<style lang="scss">
  .map-container-meters{
    height: 585px;
  }
  .sizeStatic-meters {
    height: 570px;
  }
  .card_statisc-meters {
    height: 250px;
  }
</style>
<template>
  <div>
    <b-row>
      <b-col md="12" class="text-right mb-2" v-if="userRole !== 'I'">
        <b-button variant="primarySpore" class="btn-icon" @click="showConfig = !showConfig" :disabled="parseInt(response.status) === 2">
          <feather-icon icon="SettingsIcon" />  Comandos a Medidor
        </b-button>
      </b-col>
      <b-col md="12" v-if="showConfig">
        <b-card>
          <b-row>
            <b-col md="4" class="mt-2">
              <h4>Corte / Reconección: </h4>
              <b-form-checkbox
                class="custom-control-warning"
                name="check-button"
                v-model="relay"
                @change="sendCutConect(response.uuid)"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="ZapIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="ZapOffIcon" />
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" lg="6" class="mb-2">
        <b-card class="card_statisc-meters">
          <b-row>
            <b-col md="6" class="mb-1">
              <h4>Número gabinete: </h4>
              <label class="cursor-pointer font-medium-1" style="font-size: 20px" @click="redirect(response.uuidCabinet)">{{response.numberCabinets}}
                &nbsp;
                <feather-icon icon="ExternalLinkIcon" size="18"/>
              </label>
            </b-col>
            <b-col md="6" class="mb-1">

            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Número medidor: </h4>
              <label class="font-medium-1" style="font-size: 20px">{{response.number}}</label>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Tipo de medidor: </h4>
              <label class="font-medium-1" style="font-size: 20px">{{(response.type == 1 ? 'Monofásico': response.type == 2 ? 'Bifásico': response.type == 3 ? 'Trifásico': 'Otro') }}</label>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Fecha Ultima transmisión: </h4>
              <label class="font-medium-1" style="font-size: 20px">{{ response.transmitedAt }}</label>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Registros SiNaMed: </h4>
              <label class="cursor-pointer font-medium-1" style="font-size: 20px" @click="redirectSinamed(uuid)">Ver detalle
                &nbsp;
                <feather-icon icon="ExternalLinkIcon" size="18"/>
              </label>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="6" md="12" v-if="response.transmitedAt === '-'">
        <b-card class="card_statisc-meters">
          <b-row>
            <b-col
              md="12"
              class="mb-1 mt-1"
            >
              <statistic-card-vertical
                icon="WifiOffIcon"
                statistic="No ha transmitido aún"
                statistic-title="Estado"
                color="danger"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="3" md="12" v-if="response.transmitedAt != '-'">
        <b-card class="card_statisc-meters">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="BatteryChargingIcon"
                :statistic="response.storedEnergy + ' Kw/h'"
                statistic-title="Energía Acumulada"
                color="primary"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="3" md="12" v-if="response.transmitedAt != '-'">
        <b-card class="card_statisc-meters">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="CloudLightningIcon"
                :statistic="response.electricCurrent + ' A'"
                statistic-title="Corriente"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12" v-if="response.transmitedAt != '-'">
        <b-card class="card_statisc-meters">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="ActivityIcon"
                :statistic="response.frecuency + ' Hz'"
                statistic-title="Frecuencia"
                color="warning"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12" v-if="response.transmitedAt != '-'">
        <b-card class="card_statisc-meters">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="ZapIcon"
                :statistic="response.voltage + ' V'"
                statistic-title="Voltaje"
                color="info"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12" v-if="response.transmitedAt != '-'">
        <b-card class="card_statisc-meters">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                :icon="(parseInt(response.status) === 0 ? 'CloudOffIcon' : (parseInt(response.status) === 1 ? 'CloudIcon' : (parseInt(response.status) === 2 ? 'ServerIcon' : 'CloudIcon')))"
                :statistic="(parseInt(response.status) === 0 ? 'Sin servicio' : (parseInt(response.status) === 1 ? 'Con servicio' : (parseInt(response.status) === 2 ? 'Comando en espera de ejecución' : 'Con servicio')))"
                statistic-title="Estado"
                :color="(parseInt(response.status) === 0 ? 'secondary' : (parseInt(response.status) === 1 ? 'success' : (parseInt(response.status) === 2 ? 'warning' : 'success')))"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8">
        <b-card>
          <b-card-body>
            <b-row>
              <b-col md="10">
                  <h4>Ubicación</h4>
              </b-col>
              <b-col md="12">
                <div class="map-container-meters basic-example" v-if="status_mapa">
                  <l-map :center="center" :zoom="zoom" v-if="response" :options="{attributionControl: false}">
                    <l-tile-layer :attribution="attribution" :url="url">
                    </l-tile-layer>
                    <l-control-attribution position="bottomright" prefix="Spore Cloud"></l-control-attribution>
                    <l-marker :lat-lng="marker" ref="position_marker">
                      <l-icon :icon-anchor="staticAnchor">
                        <b-img
                          rounded
                          :src="require(`@/assets/images/markers/0.svg`)"
                          height="50"
                        />
                      </l-icon>
                    </l-marker>
                  </l-map>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card>
          <b-card-header>
            <b-card-title>Últimas lecturas</b-card-title>
          </b-card-header>
          <b-card-body class="sizeStatic-meters" style="overflow-y:scroll;">
            <b-row>
              <b-col md="12" class="text-center" v-if="responseLog.length === 0">
                <h5>No hay lecturas en el Gabinete</h5>
              </b-col>
            </b-row>
            <b-table
              v-if="responseLog.length > 0"
              class="position-relative"
              hover
              responsive
              primary-key="id"
              show-empty
              :items="responseLog"
              :fields="tableColumnsLog"
            >
              <template #cell(Transmited)="data">
                <div class="text-nowrap selectable" v-if="data.item.createdAt">
                  <b-avatar
                    v-if="data.item.createdAt"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/fecha.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ format(data.item.createdAt) }}</span>
                </div>
              </template>
            </b-table>
          </b-card-body>
          <b-row>
            <b-col md="12" class="text-center">
              <b-link :to="`/meters/${uuid}/logs`">Ver más</b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BImg, BCardTitle, BCardHeader, BTable, BAvatar, BButton, BFormCheckbox, BLink
} from 'bootstrap-vue'
import request from '@/libs/request/index'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { formatDateLanguages, empty, decimales } from '@/libs/tools/helpers'
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LControlAttribution
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

/* global L:true */
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlAttribution,
    BRow,
    BCol,
    BCard,
    BCardBody,
    StatisticCardVertical,
    BImg,
    BCardTitle,
    BCardHeader,
    BTable,
    BAvatar,
    BButton,
    BFormCheckbox,
    BLink
  },
  data () {
    return {
      userRole: this.$store.state.session.AppActiveUser().userRole.toString(),
      uuid: this.$route.params.uuid,
      response: [],
      responseLog: [],
      zoom: 17,
      center: L.latLng(47.41461, -1.206607),
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution: '',
      marker: L.latLng(47.41461, -1.206607),
      staticAnchor: [12, 27],
      status_mapa: false,
      showConfig: false,
      relay: false,
      relayData: '',
      tableColumnsMetering: [
        { key: 'Number', label: 'Número', sortable: true },
        { key: 'ElectricCurrent', label: 'Lectura', sortable: true },
        { key: 'Position', label: 'Posición', sortable: true },
        { key: 'Status', label: 'Estatus', sortable: true },
        { key: 'Transmited', label: 'Última transmisión', sortable: true }
      ],
      tableColumnsLog: [
        { key: 'Transmited', label: 'Última transmisión', sortable: true }
      ]
    }
  },
  methods: {
    decimal (val, n) {
      return decimales(val, n)
    },
    redirect (uuid) {
      if (!empty(uuid)) {
        this.$router.push(`/gabinets/${uuid}/detail`)
      }
    },
    redirectSinamed (uuid) {
      if (!empty(uuid)) {
        this.$router.push(`/meters/${uuid}/sinamedLogs`)
      }
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    async getDetail () {
      const params = {
        url: `/meters/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.response = {
          createdAt: data.data.data[0].createdAt,
          electricCurrent: decimales(data.data.data[0].electricCurrent, 3),
          frecuency: decimales(data.data.data[0].frecuency, 2),
          latlong: data.data.data[0].latlong,
          number: data.data.data[0].number.replace(/^(0)+/, ''),
          type: data.data.data[0].type,
          numberCabinets: !empty(data.data.data[0].cfeCabinet) ? data.data.data[0].cfeCabinet.number : '-',
          uuidCabinet: !empty(data.data.data[0].cfeDevice) ? data.data.data[0].cfeDevice.uuid : '',
          deviceSerie: !empty(data.data.data[0].cfeDevice) ? data.data.data[0].cfeDevice.serie : '',
          position: data.data.data[0].position,
          status: data.data.data[0].status,
          storedEnergy: decimales(data.data.data[0].storedEnergy, 2),
          transmitedAt: (!empty(data.data.data[0].transmitedAt) ? this.format(data.data.data[0].transmitedAt) : '-'),
          updatedAt: data.data.data[0].updatedAt,
          uuid: data.data.data[0].uuid,
          voltage: decimales(data.data.data[0].voltage, 2)
        }
        this.relay = parseInt(data.data.data[0].status) === 1
        this.responseLog = data.data.data[0].cfeMetersRecords
        if (!empty(data.data.data[0].latlong)) {
          const lat = data.data.data[0].latlong.split(',')[0]
          const long = data.data.data[0].latlong.split(',')[1]
          this.marker = L.latLng(lat, long)
          this.center = L.latLng(lat, long)
        }
        this.status_mapa = true
      })
    },
    sendCutConect (uuid) {
      this.sendComand(uuid, 'M', 'R', this.relay ? '1' : '0')
    },
    sendComand (uuid, type, command, state) {
      this.$swal({
        title: '¿Estas seguro?',
        text: 'Estos comandos se verán reflejados una vez que el dispositivo transmita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `/devices/command/${uuid}`,
            method: 'POST',
            params: {
              type: type,
              command: command,
              state: state
            }
          }
          await request(params).then(data => {
          })
        }
        this.getDetail()
        this.showConfig = false
      })
    }
  },
  created () {
    this.getDetail()
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
